import { FormHelperText, Typography } from "@material-ui/core";
import React from "react";

export default function FieldErrors({
  name,
  errorsForm,
  errorsApi
}) {
  if (errorsApi && errorsApi[name]) {
    return (
      <>
        {errorsApi[name].map(error => {
          return (<><Typography variant="caption" style={{ color: 'red' }}>* {error}</Typography><br/></>);
        })}
      </>
    );
  }

  if (errorsForm && (errorsForm[name] || errorsForm[`obj_${name}`])) {
    const current = errorsForm[name] ? errorsForm[name] : errorsForm[`obj_${name}`];

    return (
      <>
        <FormHelperText error={true}>
          {current.type === "required" && <span>Esse campo é obrigatório</span>}
          {current.type === "maxLength" && <span>Tamanho máximo excedido</span> }
        </FormHelperText>
      </>
    );
  }

  return <></>;
}
