import React, { useState } from "react"
import LayoutLogged from "../../components/layout/logged"
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import api from '../../services/api';
import { setCurrentUser } from '../../services/auth';
import { Alert } from '@material-ui/lab';
import SendButton from "../../components/form/send-button";
import Swal from 'sweetalert2';
import ApiLoading from "../../components/data/api-loading";
import UserForm from "../../components/pages/user-form";
import PagePadding from "../../components/layout/page-padding";

export default function Profile() {
  const { control, setValue, handleSubmit, formState: { errors } } = useForm();
  const [errorsApi, setErrorsApi] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);

  const onSubmit = async data => {
    setErrorsApi(undefined);
    setLoading(true);

    try {
      const response = await api.put(`/me`, data);
      setCurrentUser(response.data);
      setLoading(false);

      Swal.fire({
        title: 'Dados atualizados com sucesso!',
        icon: 'success',
        confirmButtonColor: '#000',
      });
    } catch (error) {
      setErrorsApi(error.response.data);
      setLoading(false);
    }
  };

  return (
    <LayoutLogged title="Meus dados">
      <PagePadding>
        <Container component="main" maxWidth="md">
          <ApiLoading
            api='/me'
            onLoaded={(user) => {
              setUser(user);
            }}>
            {user && (
              <form onSubmit={handleSubmit(onSubmit)} method="post">
                <UserForm
                  control={control}
                  errorsForm={errors}
                  errorsApi={errorsApi}
                  user={user}
                  setValue={setValue}
                />

                {(Object.keys(errors).length > 0 || errorsApi) && <Alert severity="error">Erro ao tentar salvar os dados.</Alert>}

                <SendButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  loading={loading}
                >
                  Salvar
              </SendButton>
              </form>
            )}
          </ApiLoading>
        </Container>
      </PagePadding>
    </LayoutLogged>
  );
}